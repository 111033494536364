import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"
import { animateScroll as scroll } from "react-scroll"

import BurnoutProofHeader from "./BurnoutProofHeader"
import BurnoutProofFormSection from "./BurnoutProofFormSection"

import { getBurnoutProof } from "../../../requesters/CalculatorRequester"

import { submitBurnoutProof } from "../../../requesters/CalculatorResultsRequester"

const BurnoutProofFormPage = ({ baseUrl, currentUser }) => {
  const [questions, setQuestions] = useState()
  const [form, setForm] = useState({})
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [inflight, setInflight] = useState(false)
  const [invalidInputId, setInvalidInputId] = useState(false)
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "Performance Intelligence Academy | Burnout Proof Questions"
  }, [])

  const fetchQuestionSet = () => {
    getBurnoutProof(baseUrl, currentUser.active_burnout_proof_id, currentUser)
    .then(res => {
      setQuestions(res.response.json.calc.grouped_questions[0].questions)
      setTotalQuestionLength(res.response.json.calc.question_length)
    })
    // eslint-disable-next-line no-console
    .catch(err => console.log(err))
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in an active Pulse Check
    if (!currentUser?.active_burnout_proof_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      scroll.scrollTo(15)
    }
  }, [])

  useEffect(() => {
    const invalidInputDetected = _.toArray(form).find(item => item.float_field === "")
    setInvalidInputId(invalidInputDetected?.answer_id)
  }, [form])

  // Route to sociodemographic questions when they are not complete
  if (!currentUser.active_enrolment?.sociodemographic_complete) {
    window.location.href = "/demographic/burnout-proof"
    return null
  }

  const onSelectHandler = e => {
    const { id, name } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id }
    setForm(formCopy)

    // Scroll to next question
    const { innerWidth } = window
    scroll.scrollMore(innerWidth <= 770 ? 572 : 270)
  }

  const onSubmit = () => {
    setInflight(true)
    const payload = _.toArray(form)
    submitBurnoutProof(baseUrl, { payload }, currentUser).then(res => {
      if (res.response?.status === "ok") {
        window.location.href = `/burnout-proof-dashboard`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }


  return (
    <div className="container">
      <BurnoutProofHeader
        form={form}
        inflight={inflight}
        invalidInput={!!invalidInputId}
        totalQuestionLength={totalQuestionLength}
        onSubmit={onSubmit} />
      <h1>Burnout Proof Calculator</h1>
      <p className="mt-0">Please answer the following questions reflecting on your experiences in the past month.</p>
      <p>
        Click on the &quot;Done&quot; button on the top right-hand corner when complete.
      </p>
      <hr />
      {!!questions?.length && questions.map(question => (
        <BurnoutProofFormSection
          key={`question-${question.id}`}
          onSelectHandler={onSelectHandler}
          question={question}
          questionId={question.id}
          selectedAnswer={form[question.id]} />
      ))}
    </div>
  )
}

export default BurnoutProofFormPage
