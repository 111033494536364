import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { searchForUsers } from "../../requesters/Backend/UserRequester"

const BackendDashboard = ({ baseUrl, currentUser }) => {
  const [userList, setUserList] = useState([])
  const [query, setQuery] = useState("")
  const [inflight, setInflight] = useState(false)
  const [isNewSearch, setIsNewSearch] = useState(false)

  const handleSearch = e => {
    e.preventDefault()
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    const search = {}

    if (regexExp.test(query)) {
      search.id_search = query
    } else {
      search.search = query
    }
    searchForUsers(baseUrl, search, currentUser).then(res => {
      setUserList(res.response.json.users)
      setInflight(false)
      setIsNewSearch(false)
      return null
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    setIsNewSearch(true)
  }, [query])

  useEffect(() => {
    // Work around so that we can call the search on initial load
    handleSearch({ preventDefault: () => {} })
  }, [])

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="page-header">
          <div className="d-flex justify-content-between align-items-center">
            <h2>Users</h2>
          </div>
          <form className="d-flex justify-content-between align-items-center" onSubmit={handleSearch}>
            <input type="text" className="form-control" placeholder="Search by first name, surname, email address or User ID" value={query} onChange={e => setQuery(e.target.value)} />
            <button disabled={!isNewSearch} type="submit" className="btn btn-main">Search</button>
          </form>
        </div>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Surname</th>
                <th>Contact Email</th>
                <th>ID</th>
                <th>Unit</th>
                <th>Program</th>
              </tr>
            </thead>
            <tbody>
              {inflight ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : (
                <>
                  {userList.map(user => (
                    <tr key={user.id}>
                      <td><Link to={`/backend/users/${user.id}`}>{user.firstname}</Link></td>
                      <td><Link to={`/backend/users/${user.id}`}>{user.surname}</Link></td>
                      <td>{user.email}</td>
                      <td>{user.id}</td>
                      <td><Link to={`/backend/units/${user.unit_id}`}>{user.unit_name}</Link></td>
                      <td><Link to={`/backend/programs/${user.program_id}`}>{user.program_name}</Link></td>
                    </tr>
                  ))}
                </>
              )}
              {userList.length === 0 && !inflight && (
                <tr>
                  <td colSpan="6">No results found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default BackendDashboard