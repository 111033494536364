import React, { useState, useEffect } from "react"
import { useToasts } from "react-toast-notifications"
import { updateUser, updateUserPassword } from "../../requesters/UsersRequester"
import { passwordRegex } from "../../util/regex"
import { DashboardBody } from "../Dashboard/styles"

const SettingsPage = ({ baseUrl, currentUser }) => {
  const [firstname, setFirstname] = useState(currentUser.firstname)
  const [surname, setSurname] = useState(currentUser.surname)
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false)
  const [showConfirmPasswordHandler, setShowConfirmPasswordHandler] = useState(false)
  const [currentPassword, setCurrentPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [validNewPassword, setValidNewPassword] = useState(false)

  const [showNewPwdError, setShowNewPwdError] = useState(false)
  const [showMatchPwdError, setShowMatchPwdError] = useState(false)
  const [showSameCurrentPwdError, setShowSameCurrentPwdError] = useState(false)

  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "Performance Intelligence Academy | Settings"
  }, [])

  const onUpdateUser = () => {
    if (firstname !== currentUser.firstname || surname !== currentUser.surname) {
      updateUser(baseUrl, { firstname, surname }, currentUser).then(res => {
        if (res.status === 200) {
          addToast(`User name updated sucessfully`, { appearance: "success" })
        }
      }).catch(err => {
        addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
      })
    }
  }

  const validatePassword = () => {
    setValidNewPassword(passwordRegex.test(newPassword))
    if (!validNewPassword) {
      setShowNewPwdError(true)
    }
  }

  const validateConfirmPassword = () => {
    if (confirmPassword !== newPassword) {
      setShowMatchPwdError(true)
      return
    }
    if (currentPassword === newPassword) {
      setShowSameCurrentPwdError(true)
    }
  }

  const onSubmitPasswordChange = () => {
    if (
      currentPassword
      && newPassword
      && newPassword === confirmPassword
      && newPassword !== currentPassword
      && validNewPassword
    ) {
      const params = {
          current_password: currentPassword,
          password: newPassword,
          password_confirmation: confirmPassword,
      }
      updateUserPassword(baseUrl, params, currentUser).then(res => {
        if (res.status === 200) {
          setChangePasswordSuccess(true)
          setShowConfirmPasswordHandler(false)
          // DEV-NOTE: when changing password with devise user has to sign in again...
          // redirecting to homepage for now but to fix add "trackable" columns to user model in db/migrate/20190817222943_devise_create_users.rb
          // https://stackoverflow.com/questions/55735895/nomethoderror-undefined-method-current-sign-in-at-for-user0x000055ce01dcf0a
          window.location.href = "/logout"
        }
      })
    } else {
      setShowNewPwdError(false)
      setShowMatchPwdError(false)
      setShowSameCurrentPwdError(false)
    }
  }

  const onShowChangePassword = () => {
    setShowConfirmPasswordHandler(true)
  }

  const onChangeFirstName = e => {
    setFirstname(e.target.value)
  }

  const onChangeLastName = e => {
    setSurname(e.target.value)
  }

  const onChangeCurrentPassword = e => {
    setCurrentPassword(e.target.value)
  }

  const onChangeNewPassword = e => {
    setShowNewPwdError(false)
    setNewPassword(e.target.value)
  }

  const onChangeConfirmPassword = e => {
    setShowMatchPwdError(false)
    setShowSameCurrentPwdError(false)
    setConfirmPassword(e.target.value)
  }

  return (
    <DashboardBody>
      <div className="content">
        <div className="container form-container">
          <h1>Settings</h1>
          <form className="new_user" id="new_user" action="/users/sign_in" acceptCharset="UTF-8" method="post">
            <div className="card">
              <div className="card-header"><h5>Account Settings</h5></div>
              <div className="card-body">
                <div className="form-group">
                  <label>Name</label>
                  <div className="row">
                    <div className="col-6">
                      <input
                        autoComplete="first_name"
                        className="form-control"
                        type="first_name"
                        name="first_name"
                        id="user_first_name"
                        onChange={onChangeFirstName}
                        placeholder={currentUser?.firstname} />
                    </div>
                    <div className="col-6">
                      <input
                        autoComplete="last_name"
                        className="form-control"
                        type="last_name"
                        name="last_name"
                        id="user_last_name"
                        onChange={onChangeLastName}
                        placeholder={currentUser?.surname} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <h6>{currentUser?.email}</h6>
                  {/* Disabling User ability to change email address */}
                  {/* <input
                    autoComplete="email"
                    className="form-control"
                    type="email"
                    name="email"
                    id="user_email"
                    onChange={onChangeEmail}
                    placeholder={currentUser?.email} /> */}
                </div>
                <div className="form-group">
                  <label>Organisation</label>
                  <h6>{currentUser?.organisation_info?.name}</h6>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header"><h5>Password</h5></div>
              <div className="card-body">
                <div className="form-group">
                  {showConfirmPasswordHandler ? (
                    <>
                      <div className="row mt-4">
                        <div className="col-6">
                          <div className="form-group">
                            <label>Current Password</label>
                            <input
                              autoComplete="current_password"
                              className="form-control"
                              type="password"
                              name="current_password"
                              id="user_current_password"
                              onChange={onChangeCurrentPassword} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label>New Password</label>
                            <input
                              autoComplete="new_password"
                              className="form-control"
                              type="password"
                              name="new_password"
                              id="user_new_password"
                              onChange={onChangeNewPassword}
                              onBlur={validatePassword} />
                            {showNewPwdError && <div className="err-msg text-danger">Passwords must be between 6 and 25 characters and include a number, a symbol, a lowercase letter and an uppercase letter.</div>}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label>Confirm New Password</label>
                            <input
                              autoComplete="confirm_password"
                              className="form-control"
                              type="password"
                              name="confirm_password"
                              id="user_confirm_password"
                              onChange={onChangeConfirmPassword}
                              onBlur={validateConfirmPassword} />
                            {showMatchPwdError && <div className="err-msg text-danger">The new passwords must match</div>}
                            {showSameCurrentPwdError && <div className="err-msg text-danger">The new password must not be the same as the current one</div>}
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-main"
                        disabled={showNewPwdError || showMatchPwdError || showSameCurrentPwdError}
                        onClick={onSubmitPasswordChange}>
                        Update
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-main ml-2"
                        onClick={() => setShowConfirmPasswordHandler(false)}>
                        Cancel
                      </button>
                    </>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-main"
                        onClick={onShowChangePassword}>
                        Change Password
                      </button>
                    )}
                  {changePasswordSuccess && <div>Successfully updated password</div>}
                </div>
              </div>
            </div>
          </form>
          <div className="form-container-cta bg-transparent shadow-none p-0 mb-5">
            <button
              type="submit"
              className="btn btn-main"
              onClick={onUpdateUser}>
              Update
            </button>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default SettingsPage