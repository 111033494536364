import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { DashboardBody } from "../styles"
import { getLiveLifeDashboardResults } from "../../../requesters/CalculatorResultsRequester"
import WellbeingIndexChart from "../WellbeingIndexChart"
import IndexGauge from "../IndexGauge"
import { OVERVIEW_PAGE_CONTENT } from "../constants"
import LiveLifeDashboardEmptyState from "./LiveLifeDashboardEmptyState"
import { getLiveLifeOverallDesc } from "./constants"
import LiveLifeBreakdownCard from "./LifeLifeBreakdownCard"

const LiveLifeDashboard = ({ baseUrl, currentUser }) => {
  const [surveyResults, setSurveyResults] = useState([])
  const [wellbeingIndexes, setWellbeingIndexes] = useState([])
  const [recommendations, setRecommendations] = useState({})
  const [inflight, setInflight] = useState(true)
  const unfinishedSurveyExists = currentUser.active_enrolment.is_new_attempt_live_life

  useEffect(() => {
    document.title = "Performance Intelligence Academy | LiveLife Dashboard"
  }, [])

  const fetchResults = () => {
    getLiveLifeDashboardResults(baseUrl, currentUser).then(res => {
      if (res.response.json?.calculator_results) {
        const recentResults = res.response.json?.calculator_results
        setSurveyResults(recentResults)
        setRecommendations(recentResults[0]?.recommendations)
        setWellbeingIndexes(recentResults.map(result => ({ wellbeing_index: result.wellbeing_index, completed_at_month: result.completed_at_month })))
      }
      setInflight(false)
      return null
    }).catch(err => {
      setInflight(false)
      // eslint-disable-next-line no-console
      console.log(err)
    })
  }

  useEffect(() => {
    fetchResults()
  }, [])

  if (inflight) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    )
  }

  // // Route to linking code page when User not enrolled in a program
  if (!currentUser?.active_enrolment) {
    window.location.href = "/overview"
  }

  // Render empty state page if no results are found
  if (!surveyResults?.length && !inflight) {
    return <LiveLifeDashboardEmptyState unfinishedSurveyExists={unfinishedSurveyExists} />
  }

  return (
    <DashboardBody>
      <div className="content">
        <div className="container dashboard" id="dashboard-10dwc">
          <div className="d-flex align-items-center justify-content-between py-4">
            <div>
              <Link
                to="/overview"
                className="back-to-overview">
                <img src="/assets/icons/icn-back-009e79b5747b122738def7d3b12743f2fc8a033afbefde260aa458eb38a9f2db.svg" />
              </Link>
              <img src={OVERVIEW_PAGE_CONTENT.live_life.logoSrc} />
            </div>
            <div className="start-survey">
              <Link className="btn btn-main ml-4" to="/livelife/intro">{`${unfinishedSurveyExists ? "Start" : "Continue"} Survey`}</Link>
              <div className="small">{`Last completed on ${surveyResults[0]?.completed_at}`}</div>
            </div>
          </div>
          <div className="dash-row row wellness-index">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">LiveLife Score Index</div>
                  <div className="gauge">
                    <IndexGauge
                      category={surveyResults[0]?.wellbeing_index_category}
                      color={surveyResults[0]?.wellbeing_index_color}
                      index={surveyResults[0]?.wellbeing_index} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card">
                <div className="card-body">
                  <div className="pre-text">LiveLife Score Trend</div>
                  <WellbeingIndexChart results={wellbeingIndexes} />
                </div>
              </div>
            </div>
          </div>
          <div className="dash-row row">
            <div className="col-lg-4"><Link className="btn btn-lg-block bg-main-120" to="/about-livelife">What is LiveLife score?</Link></div>
            {currentUser.active_enrolment?.live_life_latest_submitted_id && (
              <div className="col-lg-8">
                <a
                  className="btn btn-lg-block btn-outline-main"
                  target="_blank"
                  rel="noreferrer"
                  href={`/calculator_results/${currentUser.active_enrolment?.live_life_latest_submitted_id}.pdf`}>
                  Download Latest Report
                </a>
              </div>
            )}
          </div>
          <LiveLifeBreakdownCard surveyResults={surveyResults} />
          <div className="dash-row recommendations">
            <div className="row">
              <div className="col-xl-3">
                <div className="pre-text">How to improve</div>
                <h3>Recommendations</h3>
                <div className="mb-3">
                  {getLiveLifeOverallDesc(surveyResults[0]?.wellbeing_index || 0)}
                </div>
              </div>
              {
                !!recommendations?.length && recommendations.map(recommendation => (
                  <div className="col-xl-3" key={recommendation.heading}>
                    <div className="card recommendations-item">
                      <div className="card-body">
                        <div className="mb-4">
                          {/* <img className="mx-3" src={MFC_CATEGORIES[recommendation.category_slug].srcLink} width={48} /> */}
                          <h6 className="mb-3 pt-4 recommendations-header">{recommendation.heading}</h6>
                          {recommendation?.sub_heading && (
                            <small>{recommendation?.sub_heading}</small>
                          )}
                        </div>
                        <ul>
                          {recommendation.content.map((text, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <li key={`${text.slice(0, 3)}-${index}`}>{text}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </DashboardBody>
  )
}

export default LiveLifeDashboard
