import React, { useEffect, useState } from "react"
import { useToasts } from "react-toast-notifications"

import _ from "lodash"
import { getCalculatorQuestions } from "../../../requesters/CalculatorRequester"

import { getCalculatorResult, updateCalculatorResult } from "../../../requesters/CalculatorResultsRequester"
import FormSection from "./FormSection"
import GeneralInfoSection from "./GeneralInfoSection"
import Header from "./Header"
import {
  HeartRateFormSection, MovementFormSection, RecoveryFormSection, SittingTimeFormSection
} from "./SpecificFormSections"

const FormPage = ({ baseUrl, currentUser, match }) => {
  const [questionSets, setQuestionSets] = useState()
  const [form, setForm] = useState({})
  const [currentSection, setCurrentSection] = useState(100)
  const [totalQuestionLength, setTotalQuestionLength] = useState(0)
  const [lastPage, setLastPage] = useState(6)
  const [resultId, setResultId] = useState("")
  const { moduleId } = match.params
  const { addToast } = useToasts()

  useEffect(() => {
    document.title = "Performance Intelligence Academy | Matchfit Calculator Questions"
  }, [])

  const fetchQuestionSet = () => {
    const params = { id: currentUser.active_matchfit_id, calculator_type: "matchfit", module_id: moduleId }
    getCalculatorQuestions(baseUrl, params, currentUser).then(res => {
      setQuestionSets(res.response.json.calc.grouped_questions)
      setTotalQuestionLength(res.response.json.calc.question_length)
      setLastPage(res.response.json.calc.last_page)
    })
  }

  const fetchCalculatorResult = () => {
    getCalculatorResult(baseUrl, currentUser, "matchfit").then(res => {
      const {
        calc_responses: calculatorResponses,
        first_module_progress,
        second_module_progress,
        third_module_progress,
        fourth_module_progress
      } = res.response.json?.calculator_results
      if (calculatorResponses?.length) {
        const retrievedAnswers = {}
        calculatorResponses.forEach(cr => {
          retrievedAnswers[cr.question_slug] = {
            answer_id: cr.answer_id,
            answer_slug: cr.answer_slug,
            free_text_field: cr.free_text_field,
            number_field: cr.float_field || cr.number_field,
            hours: cr.hours,
            minutes: cr.minutes,
            seconds: cr.seconds,
            // sorting_index: cr.question_sorting_index
          }
        })
        setForm(retrievedAnswers)

        // Retrieve User's progress on the module
        switch (moduleId) {
          case "body":
            setCurrentSection(first_module_progress || 0)
            break
          case "movement":
            setCurrentSection(second_module_progress || 0)
            break
          case "physical-functioning":
            setCurrentSection(third_module_progress || 0)
            break
          case "brain":
            setCurrentSection(fourth_module_progress || 0)
            break
          default:
            setCurrentSection(0)
            break
        }
      } else {
        setCurrentSection(0)
      }
      setResultId(res.response.json?.calculator_results?.id)
    })
  }

  useEffect(() => {
    // Route to linking code page when User not enrolled in a program
    if (!currentUser?.active_matchfit_id) {
      window.location.href = "/overview"
    } else {
      fetchQuestionSet()
      fetchCalculatorResult()
    }
  }, [])

  const onChangeHandler = e => {
    const { name, id } = e.target
    const formCopy = { ...form }
    const answer_slug = e.target.getAttribute("slug")
    formCopy[name] = { answer_id: id, answer_slug }

    // TODO: wipe appropriate fields when user selects "No"
    if (answer_slug === "tobacco_yes_no_1") {
      delete formCopy.tobacco_cigarettes_quantity
      delete formCopy.tobacco_cigars_quantity
      delete formCopy.tobacco_pipes_quantity
      delete formCopy.tobacco_vapes_quantity
    } else if (answer_slug === "wears_activity_monitor_1") {
      delete formCopy.monitor_tracks_minutes
      delete formCopy.monitor_tracks_heartbeat
    }

    setForm(formCopy)
  }

  const onFreeTextChangeHandler = e => {
    const { id, name, value } = e.target
    const formCopy = { ...form }
    formCopy[name] = { answer_id: id, free_text_field: value }
    setForm(formCopy)
  }

  const onInputChangeHandler = e => {
    const {
      name, id, value, hours, minutes, seconds
    } = e.target
    const formCopy = { ...form }
    formCopy[name] = {
      answer_id: id,
      number_field: value,
      hours,
      minutes,
      seconds
    }
    setForm(formCopy)
  }

  const onSaveHandler = () => {
    const formWithFloatFields = { ...form }
    const {
      sit_to_stands,
      left_leg_balance,
      right_leg_balance,
      tobacco_cigarettes_quantity,
      tobacco_cigars_quantity,
      tobacco_pipes_quantity,
      tobacco_vapes_quantity,
    } = formWithFloatFields

    if (sit_to_stands?.number_field) {
      sit_to_stands.float_field = sit_to_stands.number_field
      delete sit_to_stands.number_field
    }
    if (left_leg_balance?.number_field) {
      left_leg_balance.float_field = left_leg_balance.number_field
      delete left_leg_balance.number_field
    }
    if (right_leg_balance?.number_field) {
      right_leg_balance.float_field = right_leg_balance.number_field
      delete right_leg_balance.number_field
    }
    if (tobacco_cigarettes_quantity?.number_field) {
      tobacco_cigarettes_quantity.float_field = tobacco_cigarettes_quantity.number_field
      delete tobacco_cigarettes_quantity.number_field
    }
    if (tobacco_cigars_quantity?.number_field) {
      tobacco_cigars_quantity.float_field = tobacco_cigars_quantity.number_field
      delete tobacco_cigars_quantity.number_field
    }
    if (tobacco_pipes_quantity?.number_field) {
      tobacco_pipes_quantity.float_field = tobacco_pipes_quantity.number_field
      delete tobacco_pipes_quantity.number_field
    }
    if (tobacco_vapes_quantity?.number_field) {
      tobacco_vapes_quantity.float_field = tobacco_vapes_quantity.number_field
      delete tobacco_vapes_quantity.number_field
    }
    const payload = _.toArray(form)
    updateCalculatorResult(baseUrl, resultId, payload, false, currentUser, moduleId, currentSection).then(res => {
      if (res.response?.status === "ok") {
        // window.location.href = `/matchfit-dashboard`
        window.location.href = `/matchfit/questions`
      }
    }).catch(err => {
      addToast(`Error: ${err.errors[0].detail}`, { appearance: "error" })
    })
  }

  return (
    <div className="container">
      <Header form={form} onSaveHandler={onSaveHandler} />
      {/* Loading effect when formpage loading */}
      {currentSection === 100 && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      {!!questionSets && questionSets.map(questionSet => {
        if (questionSet.section_title === "General Information") {
          return (
            <GeneralInfoSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onFreeTextChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Sitting") {
          return (
            <SittingTimeFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              onInputChangeHandler={onInputChangeHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title} />
          )
        }
        if (questionSet.section_title === "Activity Monitor") {
          return (
            <FormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Heart Rate") {
          return (
            <HeartRateFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Movement") {
          return (
            <MovementFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        if (questionSet.section_title === "Switching Off" || questionSet.section_title === "Physical Recovery") {
          return (
            <RecoveryFormSection
              currentSection={currentSection}
              form={form}
              key={questionSet.page_index}
              lastPage={lastPage}
              onChangeHandler={onChangeHandler}
              onInputChangeHandler={onInputChangeHandler}
              onSubmit={onSaveHandler}
              questionSet={questionSet}
              setCurrentSection={setCurrentSection}
              title={questionSet.section_title}
              totalQuestionLength={totalQuestionLength} />
          )
        }
        return (
          <FormSection
            currentSection={currentSection}
            form={form}
            key={questionSet.page_index}
            lastPage={lastPage}
            onChangeHandler={onChangeHandler}
            onInputChangeHandler={onInputChangeHandler}
            onSubmit={onSaveHandler}
            questionSet={questionSet}
            setCurrentSection={setCurrentSection}
            title={questionSet.section_title}
            totalQuestionLength={totalQuestionLength} />
        )
      })}
    </div>
  )
}

export default FormPage
