import React, { useEffect } from "react"
import { Route, Redirect } from "react-router-dom"
import { toast } from "react-toastify"

const toastId = "private-route-error"
const containerId = "main"
const toastOptions =  {
  toastId,
  containerId
}

const ProtectedRoute = ({
  component: Component, currentUser, enableNotification = true, redirectedRoute = "/", render, title = "Performance Intelligence Academy", ...rest
}) => {
  useEffect(() => {
    document.title = title
  }, [])

  return (
    <Route
      {...rest}
      render={
        props => {
          if (currentUser?.firstname) {
            return <Component {...rest} {...props} />
          }
            if (enableNotification) {
              toast.error(
                <div>
                  <span><i className="fa fa-exclamation mr-2" /></span>
                  You must be logged in to access this page.
                </div>,
                toastOptions
              )
            }
            return (
              <Redirect to={
                {
                  pathname: redirectedRoute,
                  state: {
                    from: props.location
                  }
                }
              } />
            )
        }
    } />
  )
}

export default ProtectedRoute