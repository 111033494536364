const featureFlags = environment => {
  switch (environment) {
    case "STAGING":
      return window.location.href.includes("https://staging-portal.performanceintelligence.com/") || window.location.href.includes("localhost:3000")
    case "DEV":
      return window.location.href.includes("localhost:3000")
    default:
      return false
  }
}

export default featureFlags
